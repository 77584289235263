<template>
  <a-modal
    title=""
    class="white-close-modal"
    :width="652"
    :bodyStyle="{ padding: 0 }"
    :visible="visible"
    :footer="null"
    @cancel="close"
  >
    <div class="folder">
      <div class="banner"></div>
      <div class="top-btn">
        <div class="btn" v-if="tabActive == 1" @click="tabActive = 2">
          {{ $t("practice.practice_record") }}
        </div>
        <div class="btn" v-if="tabActive == 2" @click="tabActive = 1">
          {{ $t("go_back") }}
        </div>
      </div>
      <div class="tab" v-show="tabActive == 1">
        <div class="config">
          <div class="item">
            <span>
              {{ $t("practice.answer_time") }}：{{
                formatSeconds2(configInfo.practiceTimes * 60)
              }}
            </span>
            <div class="icon">
              <img src="@/assets/image/practice/simulation-icon-1.png" alt="" />
            </div>
          </div>
          <div class="item">
            <span>
              {{ $t("practice.paper_total_score") }}：{{
                configInfo.practiceTotalScore
              }}{{ $t("practice.score") }}
            </span>
            <div class="icon">
              <img src="@/assets/image/practice/simulation-icon-2.png" alt="" />
            </div>
          </div>
          <div class="item">
            <span>
              {{ $t("practice.total_questions") }}：{{
                configInfo.practiceQuestions
              }}{{ $t("practice.question") }}
            </span>
            <div class="icon">
              <img src="@/assets/image/practice/simulation-icon-3.png" alt="" />
            </div>
          </div>
          <div class="item">
            <span>
              {{ $t("practice.pass_line") }}：{{ configInfo.practicePassScore
              }}{{ $t("practice.score") }}
            </span>
            <div class="icon">
              <img src="@/assets/image/practice/simulation-icon-4.png" alt="" />
            </div>
          </div>
        </div>
        <template v-if="hasFolders">
          <div class="tab-title">
            {{ $t("practice.multiple_choice_question_bank") }}
          </div>
          <div class="search">
            <a-input-search
              v-model:value="keyword"
              style="width: 390px"
              :placeholder="
                $t('practice.please_enter_name_of_question_bank_to_filter')
              "
              @change="inputChange"
            />
          </div>
          <div class="tree">
            <a-tree
              checkable
              multiple
              :selectable="false"
              :tree-data="folders"
              :replace-fields="replaceFields"
              v-model:checkedKeys="checkedKeys"
            >
              <template #title="item">
                {{ item.name }}（{{ item.questionNum }}）
              </template>
            </a-tree>
            <a-empty
              v-if="!folders.length"
              :image="require('@/assets/image/no_data_3.png')"
              :image-style="{ height: '106px' }"
              style="padding: 10px 0"
            >
              <template #description>
                <span style="color: #999">{{ $t("practice.no_folders") }}</span>
              </template>
            </a-empty>
          </div>
        </template>
        <div class="btns">
          <a-button
            type="primary"
            @click="jump()"
            :disabled="
              !configInfo.practiceQuestions ||
              (!checkedKeys.length && hasFolders)
            "
          >
            {{ $t("practice.enter_practice") }}
          </a-button>
        </div>
      </div>
      <div class="tab" v-show="tabActive == 2">
        <div class="tab-title">
          {{ $t("practice.practice_record") }}
        </div>
        <template v-if="recordList.length">
          <div class="record-content">
            <div class="row title">
              <span class="number">{{ $t("practice.serial_number") }}</span>
              <span class="score">{{ $t("practice.score2") }}</span>
              <span class="time">{{ $t("practice.answering_time") }}</span>
              <span class="date">{{ $t("practice.date") }}</span>
            </div>
            <div
              class="row"
              v-for="(item, index) in recordList"
              :key="index"
              @click="jumpResult(item)"
            >
              <span class="number">{{
                index + (currentPage - 1) * 15 + 1
              }}</span>
              <span
                class="score"
                :class="[
                  item.score >= item.practicePassScore ? 'green' : 'red',
                ]"
                >{{ item.score }}</span
              >
              <span class="time">{{
                formatSeconds(item.endTime - item.startTime)
              }}</span>
              <span class="date">
                {{ dateFormat(item.submitTime, "YYYY-MM-DD HH:mm") }}
                <RightOutlined />
              </span>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!recordList.length && !recordLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{
              $t("practice.no_practice_records")
            }}</span>
          </template>
        </a-empty>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import ls from "@/utils/local-storage";
import { debounce } from "lodash";
import { formatSeconds, dateFormat, formatSeconds2 } from "@/utils/tools";
import { practiceConfig, simulateConfig, recordList } from "@/api/practice";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    renderVNode,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      visible: false,
      tabActive: 1,
      configInfo: {
        practicePassScore: 0,
        practiceQuestions: 0,
        practiceTimes: 0,
        practiceTotalScore: 0,
        questionNum: 0,
      },
      hasFolders: false,
      folders: [],
      replaceFields: {
        children: "list",
        title: "name",
        key: "id",
        value: "id",
      },
      keyword: "",
      checkedKeys: [],
      recordList: [],
      currentPage: 1,
      pageTotal: 1,
      recordLoading: true,
    });

    const getConfig = () => [
      simulateConfig().then((res) => {
        state.configInfo = res.data || {
          practicePassScore: 0,
          practiceQuestions: 0,
          practiceTimes: 0,
          practiceTotalScore: 0,
          questionNum: 0,
        };
      }),
    ];

    const open = (hasFolders, data) => {
      getConfig();
      getRecordList();
      state.tabActive = 1;
      state.hasFolders = hasFolders;
      state.folders = JSON.parse(JSON.stringify(data));
      state.keyword = "";
      state.checkedKeys = [];
      state.visible = true;
    };

    const close = () => {
      state.visible = false;
    };

    const inputChange = debounce(() => {
      practiceConfig({
        keyword: state.keyword,
      }).then((res) => {
        state.folders = res.data.folderTree || [];
      });
    }, 500);

    let checkedQuesNum = 0;
    let folderTree = [];
    const getCheckedQuesNum = (list) => {
      list.forEach((item) => {
        if (state.checkedKeys.includes(item.id)) item.list = null;
        state.checkedKeys.forEach((id) => {
          if (item.id == id && !item.isGet) {
            item.isGet = true;
            checkedQuesNum += item.questionNum;
          } else if (item.list && item.list.length) {
            getCheckedQuesNum(item.list);
          }
        });
      });
    };

    const jump = () => {
      if (state.hasFolders) {
        checkedQuesNum = 0;
        folderTree = JSON.parse(JSON.stringify(state.folders));
        getCheckedQuesNum(folderTree);
        if (checkedQuesNum < state.configInfo.practiceQuestions) {
          proxy.$message.warning(
            proxy.$t("practice.generate_test_papers_using_method")
          );
          return false;
        }
      } else {
        if (state.configInfo.questionNum < state.configInfo.practiceQuestions) {
          proxy.$message.warning(
            proxy.$t("practice.generate_test_papers_using_method")
          );
          return false;
        }
      }
      close();
      ls.set("newPractice", true);
      router.push({
        path: "/mine/practice/paper",
        query: {
          type: 3,
          practiceTotalScore: state.configInfo.practiceTotalScore,
          folderIds: state.checkedKeys.join(),
        },
      });
    };

    const jumpResult = (item) => {
      close();
      router.push({
        path: "/mine/practice/paper",
        query: {
          type: 3,
          practiceTotalScore: item.practiceTotalScore,
          submitId: item.submitId,
          disabled: 1,
        },
      });
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getRecordList();
    };

    const getRecordList = () => {
      state.recordLoading = true;
      state.recordList = [];
      recordList({
        page: state.currentPage,
        pageSize: 15,
        practiceType: [3],
      }).then((res) => {
        state.recordLoading = false;
        state.pageTotal = res.data.totals;
        state.recordList = res.data.list || [];
      });
    };

    return {
      formatSeconds,
      dateFormat,
      formatSeconds2,
      ...toRefs(state),
      open,
      close,
      inputChange,
      jump,
      jumpResult,
      pageChange,
    };
  },
};
</script>
<style lang="less" scoped>
.folder {
  .banner {
    height: 228px;
    background-image: url("~@/assets/image/practice/simulation-banner.png");
    background-size: 100% 100%;
  }
  .top-btn {
    .mixinFlex(flex-end);
    margin-top: 24px;
    padding: 0 24px;
    .btn {
      font-size: 14px;
      color: @color-theme;
      margin-left: 20px;
      cursor: pointer;
      user-select: none;
    }
  }
  .config {
    margin-top: 24px;
    padding: 0 24px;
    .mixinFlex(space-between; center);
    flex-wrap: wrap;
    .item {
      width: 292px;
      height: 90px;
      padding: 24px;
      border-radius: 4px;
      background: #f5f9ff;
      margin-bottom: 20px;
      .mixinFlex(space-between; center);
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
      .icon {
        .mixinImgWrap(27px; 27px);
      }
    }
  }
  .search {
    padding: 0 24px;
    margin-top: 24px;
  }
  .tree {
    padding: 0 24px 0 18px;
    margin-top: 8px;
    min-height: 100px;
  }
  .btns {
    text-align: center;
    padding: 24px 0 32px;
  }
  .tab-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    border-left: 6px solid @color-theme;
    padding-left: 8px;
    margin: 24px 24px 0;
  }
  .record-content {
    padding: 0 24px;
    margin-top: 24px;
    max-height: 380px;
    overflow-y: auto;
    .row {
      .mixinFlex(space-between; center);
      font-size: 14px;
      color: #333;
      line-height: 20px;
      padding: 12px 0;
      border-bottom: 1px solid #f4f4f4;
      cursor: pointer;
      &.title {
        cursor: default;
        span {
          font-weight: 600;
        }
      }
      .number {
        width: 15%;
      }
      .score {
        width: 25%;
        &.red {
          color: #ef2b2b;
        }
        &.green {
          color: #23c664;
        }
      }
      .time {
        width: 25%;
      }
      .date {
        width: 35%;
        .mixinFlex(space-between; center);
      }
    }
  }
}
</style>
<style lang="less">
.white-close-modal {
  .ant-modal-close-x {
    color: #fff;
  }
}
</style>
